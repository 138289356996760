<template>
  <v-row>
    <v-col :class="asbestosAreaSurveyed || leadAreaSurveyed ? 'preline' : ''">
      <v-row>
        <v-col>
          <h2 class="mb-2">
            <span class="area-surveyed-step">Survey Scope</span>
          </h2>
        </v-col>
      </v-row>
      <v-sheet elevation="1" rounded outlined>
        <v-col>
          <v-row>
            <v-col class="py-0 my-0">
              <v-tabs fixed-tabs>
                <v-tab
                  @click="step = 1"
                  data-cy="asbestos-area-surveyed"
                  v-if="building.is_asbestos"
                >
                  Asbestos
                  <span
                    class="ml-3"
                    v-if="
                      !asbestosAreaSurveyed ||
                      asbestosAreaSurveyed == '' ||
                      asbestosAreaSurveyed == '<p></p>'
                    "
                    ><v-icon small color="error">fas fa-exclamation-circle</v-icon></span
                  >
                </v-tab>
                <v-tab
                  @click="step = 2"
                  data-cy="lead-area-surveyed"
                  v-if="building.is_lead"
                >
                  Lead
                  <span
                    class="ml-3"
                    v-if="
                      !leadAreaSurveyed ||
                      leadAreaSurveyed == '' ||
                      leadAreaSurveyed == '<p></p>'
                    "
                    ><v-icon small color="error">fas fa-exclamation-circle</v-icon></span
                  >
                </v-tab>
              </v-tabs>
            </v-col>
          </v-row>
          <v-row class="max-note-height">
            <v-col>
              <v-window v-model="step" class="h-100">
                <v-window-item data-cy="area-surveyed" :value="1" class="h-100">
                  <v-row class="pa-0 ma-0 h-100">
                    <v-col class="h-100 mx-0 px-0">
                      <p
                        class="preline body-1 px-3"
                        v-if="asbestosArea && asbestosArea != '<p></p>'"
                        v-html="asbestosArea"
                      ></p>
                      <div v-else class="h-100 pt-10" justify="center" align="center">
                        <v-icon class="mb-2">fas fa-exclamation</v-icon>
                        <p class="subtitle-2 text--secondary">
                          Asbestos survey note has not been set
                        </p>
                      </div>
                    </v-col>
                  </v-row>
                </v-window-item>
                <v-window-item :value="2" class="h-100 mx-0 px-0">
                  <v-row class="pa-0 ma-0 h-100">
                    <v-col data-cy="note-box" class="h-100">
                      <p
                        class="preline body-1"
                        v-if="leadArea && leadArea != '<p></p>'"
                        v-html="leadArea"
                      ></p>
                      <div v-else class="h-100 pt-10" justify="center" align="center">
                        <v-icon class="mb-2">fas fa-exclamation</v-icon>
                        <p class="subtitle-2 text--secondary">
                          Lead survey note has not been set
                        </p>
                      </div>
                    </v-col>
                  </v-row>
                </v-window-item>
              </v-window>
            </v-col>
          </v-row>
        </v-col>
      </v-sheet>
      <v-row>
        <v-col class="py-0 mb-0 mt-n12">
          <v-tooltip bottom v-if="!is_reviewed(building)">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                data-cy="edit-area-surveyed"
                v-on="on"
                :attrs="attrs"
                fab
                small
                color="info"
                @click="dialog = true"
                class="float-right elevation-0 mr-3"
              >
                <v-icon>fa fa-pen</v-icon>
              </v-btn>
            </template>
            <span>Edit Survey Scope</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <survey-scope-edit
        :step="step"
        :atemplateContent="step == 1 ? asbestosArea : leadArea"
        :dialog="dialog"
        v-on:close="dialog = false"
        @updated="$emit('updated')"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SurveyScopeEdit from "./SurveyScopeEdit.vue";
export default {
  props: ["asbestosArea", "leadArea"],
  components: {
    SurveyScopeEdit,
  },
  data: () => ({
    asbestosAreaSurveyed: null,
    leadAreaSurveyed: null,
    dialog: false,
    step: 1,
  }),
  computed: {
    ...mapGetters(["building", "is_reviewed"]),
  },
  mounted() {
    this.asbestosAreaSurveyed = this.asbestosArea;
    this.leadAreaSurveyed = this.leadArea;
  },
  methods: {
    ...mapActions(["editBuilding", "getBuilding"]),
    close() {
      this.dialog = false;
    },
  },
  watch: {
    // asbestosArea(updated) {
    //   this.asbestosAreaSurveyed = updated;
    // },
    // leadArea(updated) {
    //   this.leadAreaSurveyed = updated;
    // },
  },
};
</script>

<style scoped>
.preline {
  white-space: pre-line;
}

.max-note-height {
  height: 13em;
  overflow: auto;
}

.edit-btn {
  display: sticky;
  bottom: 5px;
}
</style>
